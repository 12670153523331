.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    &.xl{
	max-width: 1584px;

    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col{
    position: relative;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}