@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700,900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6f6f;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lucida Grande';
  margin-top: 0;
  margin-bottom: 1rem;
  color: #131313; }

p {
  margin-top: 0;
  margin-bottom: 0.5rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 700; }

small {
  font-size: .7rem; }

sub,
sup {
  position: relative;
  font-size: .75rem;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #6f6f6f;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #6f6f6f;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle;
  height: 1rem;
  width: 1rem; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

ul {
  list-style: none;
  padding-left: 0; }

@font-face {
  font-family: 'Lucida Grande';
  src: url("../fonts/LucidaGrande.woff2") format("woff2"), url("../fonts/LucidaGrande.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lucida Grande';
  src: url("../fonts/LucidaGrande-Bold.woff2") format("woff2"), url("../fonts/LucidaGrande-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px; }
  .container.xl {
    max-width: 1584px; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: transparent;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 0.8rem;
      height: 0.8rem;
      margin: 5px 7px;
      background: #fff;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #a933c8; }

html {
  font-size: 15px; }
  @media (min-width: 768px) {
    html {
      font-size: 16px; } }
  @media (min-width: 1280px) {
    html {
      font-size: 18px; } }
  @media (min-width: 1440px) {
    html {
      font-size: 19px; } }
  @media (min-width: 1600px) {
    html {
      font-size: 20px; } }

h1 {
  font-size: 2.6rem; }

h2 {
  font-size: 2.4rem; }

@media (max-width: 767px) {
  h1 {
    font-size: 2.3rem; }
  h2 {
    font-size: 2rem; } }

.btn {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background: linear-gradient(to right, #aa35ca 0%, #794bdb 100%);
  border: 1px solid transparent;
  padding: 0.7rem 3.25rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(170, 53, 202, 0.4); }

.btn:hover {
  background: linear-gradient(to right, #794bdb 0%, #aa35ca 100%);
  box-shadow: 0 0 0 0.2rem rgba(170, 53, 202, 0.4);
  color: #fff; }

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20; }
  .site-header .row {
    justify-content: space-between; }
  .site-header .logo {
    width: 14vw; }
    @media (min-width: 768px) {
      .site-header .logo {
        padding: 1rem 0; } }
    .site-header .logo img {
      max-width: 100%;
      height: auto; }
  @media (min-width: 768px) {
    .site-header .nav-menu {
      padding-top: 2rem; } }
  .site-header .nav-menu li {
    display: inline-block; }
    .site-header .nav-menu li a {
      color: #fff;
      font-size: 1.15rem;
      font-weight: 500; }
      @media (min-width: 768px) {
        .site-header .nav-menu li a {
          padding-bottom: 0.65rem;
          border-bottom: 0.2rem solid transparent; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .site-header .nav-menu li a {
          font-size: 1rem; } }
  .site-header .nav-menu .active a {
    font-weight: 900;
    border-color: #fff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .site-header .nav-menu li + li {
      margin-left: 1rem; } }
  @media (min-width: 992px) {
    .site-header .nav-menu li + li {
      margin-left: 2rem; } }
  .site-header #nav-icon {
    width: 2.5rem;
    height: 2.25rem;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer; }
  .site-header #nav-icon span {
    display: block;
    position: absolute;
    height: 0.3rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.45rem;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
  .site-header #nav-icon span:nth-child(1) {
    top: 0px; }
  .site-header #nav-icon span:nth-child(2) {
    top: 0.7rem; }
  .site-header #nav-icon span:nth-child(3) {
    top: 1.4rem; }
  .site-header #nav-icon.open span {
    background-color: #131313; }
  .site-header #nav-icon.open span:nth-child(1) {
    top: 0.9rem;
    transform: rotate(135deg); }
  .site-header #nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -3rem; }
  .site-header #nav-icon.open span:nth-child(3) {
    top: 0.9rem;
    transform: rotate(-135deg); }
  @media (max-width: 767px) {
    .site-header {
      transition: all .5s linear;
      padding: .5rem 0; }
      .site-header .logo {
        width: 7rem; }
      .site-header .navigation {
        display: none;
        flex: 0 0 100%; }
      .site-header.open-menu {
        background-color: rgba(255, 255, 255, 0.9); }
      .site-header .row {
        align-items: center; }
      .site-header .nav-menu {
        margin: 0; }
        .site-header .nav-menu li {
          display: block;
          padding: .5rem 0; }
          .site-header .nav-menu li a {
            color: #131313;
            font-size: 1.2rem; }
      .site-header .nav-toggler {
        display: block !important; } }

.content-section {
  padding: 2rem 0; }
  .content-section img {
    max-width: 100%;
    height: auto; }

.section-heading {
  text-align: center;
  position: relative;
  padding-top: 1.7rem; }
  .section-heading:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 2.55rem;
    height: 0.2rem;
    background-color: #a933c8; }

.sub-heading {
  max-width: 25.5rem;
  margin: 1rem auto;
  text-align: center; }

/*who-we-are*/
.banner {
  display: flex;
  min-height: 51.3vw;
  align-items: center; }
  .banner .content-box {
    max-width: 30rem;
    margin-top: 4rem; }
    @media (max-width: 991px) {
      .banner .content-box {
        max-width: 22rem; } }
    .banner .content-box h1 span {
      color: #555555;
      font-weight: 400; }
    .banner .content-box .btns {
      margin-top: 2rem; }
      .banner .content-box .btns a + a {
        margin-left: 1.5rem; }
    .banner .content-box svg {
      width: 1.35rem;
      height: 1.35rem;
      fill: #b8b8b8;
      transition: all .3s linear; }
      .banner .content-box svg:hover {
        fill: #a933c8; }
    .banner .content-box .icon {
      max-width: 4.05rem;
      margin-bottom: 1rem; }
    @media (min-width: 1600px) {
      .banner .content-box h1 {
        font-size: 3rem; }
      .banner .content-box p {
        font-size: 1.5rem; } }

.who-we-are {
  background: url(../images/who-we-are-bg.jpg) top right no-repeat;
  background-size: contain; }

.home,
.how-we-do {
  background: url(../images/how-we-do-bg.jpg) top right no-repeat;
  background-size: contain; }

.team .team-item:nth-child(even) {
  background-color: #f9faff; }

.team .person {
  align-items: center;
  padding: 2rem 0;
  display: flex; }
  @media (min-width: 600px) and (max-width: 991px) {
    .team .person .photo {
      flex: 0 0 35%; }
    .team .person .content {
      padding-left: 1rem; } }
  @media (min-width: 992px) {
    .team .person .photo {
      flex: 0 0 27%; }
    .team .person .content {
      padding-left: 2.5rem; } }
  @media (max-width: 599px) {
    .team .person {
      text-align: center;
      flex-direction: column; }
      .team .person .photo {
        margin-bottom: 1rem; }
      .team .person .position {
        margin-left: auto;
        margin-right: auto; } }
  .team .person h3 {
    color: #a933c8;
    font-size: 1.05rem; }
  .team .person .position {
    font-style: italic;
    font-size: 16px;
    max-width: 20.75rem; }
  .team .person .description {
    border-top: 1px solid #d0d0d0;
    padding-top: 1rem;
    margin-top: 1rem; }
    .team .person .description p:last-child {
      margin-bottom: 0; }

.team .frame {
  padding: 8px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 19%;
  max-width: 316px;
  overflow: hidden; }
  .team .frame img {
    border-radius: 18%; }

.owl-carousel .owl-stage {
  display: flex;
  align-items: center; }

.owl-theme .owl-dots .owl-dot span {
  border: 1px solid #a933c8; }

.owl-carousel {
  padding: 1rem 0; }
  .owl-carousel .owl-item {
    display: flex; }
    .owl-carousel .owl-item img {
      margin: auto; }

/**/
.infographic {
  margin: 2.5rem 0 3rem; }
  .infographic .row {
    justify-content: space-evenly; }
  @media (min-width: 992px) {
    .infographic .col:nth-child(odd) .stage {
      margin-top: 2rem; }
    .infographic .m-image {
      display: none; } }
  @media (max-width: 991px) {
    .infographic {
      display: flex;
      justify-content: center; }
      .infographic .d-image {
        display: none; }
      .infographic .row {
        flex-direction: column;
        padding-left: 1rem; }
      .infographic .stage h4 {
        margin-bottom: 0;
        font-size: .9rem; }
      .infographic .stage ul {
        margin-bottom: 0; } }
  @media (max-width: 480px) {
    .infographic .img-wrp {
      flex: 0 0 55%; } }

.stage h4 {
  text-transform: uppercase;
  font-size: 1.05rem;
  color: #6f6f6f;
  margin-bottom: .6rem; }

.stage ul {
  font-size: 0.9rem; }
  .stage ul li {
    padding-left: 1rem;
    background: url(../images/list.svg) left center no-repeat;
    background-size: .6em; }

@media (min-width: 992px) and (max-width: 1279px) {
  .stage h4 {
    font-size: 1rem; }
  .stage ul {
    font-size: 0.75rem; } }

.col-content,
.col-tabs {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (max-width: 767px) {
    .col-content,
    .col-tabs {
      flex: 0 0 100%;
      max-width: 100%; } }

@media (max-width: 767px) {
  .col-content {
    display: none; } }

.tabs-wrp .col {
  flex: 0 0 33.3%;
  max-width: 33.3%;
  margin-bottom: 1.5rem; }

@media (min-width: 1600px) {
  .tabs-wrp {
    margin-right: -35px;
    margin-left: -35px; }
    .tabs-wrp .col {
      margin-bottom: 3.5rem;
      padding-right: 35px;
      padding-left: 35px; } }

@media (min-width: 768px) and (max-width: 1279px) {
  .tabs-wrp .col {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 480px) {
  .tabs-wrp {
    margin-right: -5px;
    margin-left: -5px; }
    .tabs-wrp .col {
      margin-bottom: 10px;
      padding-right: 5px;
      padding-left: 5px; } }

.home-tabs {
  background: url(../images/home-tabs-bg.png) top left no-repeat;
  background-size: 17.95rem 28.55rem; }
  .home-tabs .tab {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    border: 2px solid #d0d0d0;
    border-radius: 20px;
    text-align: center;
    transition: all .3s linear; }
    .home-tabs .tab h4 {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 1.1rem;
      color: #555;
      line-height: 1.2;
      transition: all .3s linear; }
      @media (max-width: 480px) {
        .home-tabs .tab h4 {
          font-size: .7rem; } }
    .home-tabs .tab img {
      transition: all .1s linear; }
      @media (max-width: 480px) {
        .home-tabs .tab img {
          max-width: 50%; } }
    .home-tabs .tab:hover, .home-tabs .tab.active {
      cursor: pointer;
      background: linear-gradient(to bottom, #794bdb 0%, #aa35ca 100%);
      border-color: #fff; }
      .home-tabs .tab:hover h4, .home-tabs .tab.active h4 {
        color: #fff; }
      .home-tabs .tab:hover img, .home-tabs .tab.active img {
        filter: brightness(0) invert(1); }
  .home-tabs .tab-content {
    display: none; }
    .home-tabs .tab-content.active {
      display: block; }
    @media (min-width: 768px) {
      .home-tabs .tab-content {
        max-width: 20.5rem;
        margin-left: auto; } }
    @media (max-width: 768px) {
      .home-tabs .tab-content {
        text-align: center; } }
    .home-tabs .tab-content p:not(:last-child) {
      margin-bottom: 2rem; }
    .home-tabs .tab-content h2 {
      line-height: 1.2;
      margin-bottom: 2rem;
      position: relative;
      padding-top: 1.5rem; }
      .home-tabs .tab-content h2:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 2.55rem;
        height: 0.2rem;
        background-color: #a933c8; }
        @media (max-width: 768px) {
          .home-tabs .tab-content h2:before {
            left: 50%;
            transform: translateX(-50%); } }

.our-projects {
  background: url(../images/our-projects.jpg) top center no-repeat;
  background-size: cover; }
  @media (min-width: 992px) {
    .our-projects {
      padding: 6rem 0 12rem; } }
  .our-projects .inner {
    background-color: white;
    border: 1px solid white;
    border-radius: 2.5rem;
    box-shadow: 0 0 24.8px 2.2px #f3f6ff;
    padding: 4rem 2rem; }
  .our-projects .projects {
    position: relative; }
    .our-projects .projects .owl-item {
      justify-content: center; }
    .our-projects .projects .owl-dots {
      margin-top: 2rem !important; }
    .our-projects .projects .owl-nav {
      position: absolute;
      left: -15%;
      right: -15%;
      top: 20%; }
      .our-projects .projects .owl-nav .owl-prev {
        position: absolute;
        left: 0; }
      .our-projects .projects .owl-nav .owl-next {
        position: absolute;
        right: 0; }
      .our-projects .projects .owl-nav span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        background-color: white;
        border: 1px solid #d0d0d0;
        border-radius: 50%; }
        .our-projects .projects .owl-nav span svg {
          fill: #a933c8; }

.meet .col {
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 992px) {
  .meet {
    margin-top: -5rem;
    background: url(../images/meet-bg.png) left top no-repeat;
    background-size: 48.65rem 57.6rem;
    background-size: contain; }
    .meet .heading {
      justify-content: flex-end; }
      .meet .heading .col {
        flex: 0 0 50%;
        max-width: 50%; }
    .meet .section-heading {
      text-align: left;
      line-height: 1.2; }
      .meet .section-heading:before {
        left: 0;
        transform: none; }
      .meet .section-heading span {
        color: #555555;
        font-weight: 400; }
    .meet .meet-img {
      display: none; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .meet {
    background-position: left 3rem; } }

@media (max-width: 991px) {
  .meet {
    background: url(../images/workers-bg-sm.png) 50% 17% no-repeat;
    background-size: contain; }
    .meet .heading {
      padding-bottom: 300px; } }

.meet-wrp {
  position: relative;
  min-height: 25rem; }
  @media (min-width: 992px) {
    .meet-wrp {
      background: url(../images/workers-bg.png) right center no-repeat;
      background-size: contain; } }
  @media (min-width: 1280px) and (max-width: 1439px) {
    .meet-wrp {
      min-height: 36rem; } }
  @media (min-width: 1440px) {
    .meet-wrp {
      min-height: 48rem; } }
  @media (max-width: 991px) {
    .meet-wrp .container {
      position: absolute;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; } }

.workers {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0; }
  @media (max-width: 991px) {
    .workers {
      height: 300px;
      top: -300px;
      bottom: auto; } }

.worker {
  background-color: #e4e7ef;
  border: 8px solid white;
  box-shadow: 0 0 24.8px 2.2px #e5eaf9;
  border-radius: 50%;
  overflow: hidden;
  transform: scale(0.5);
  position: absolute;
  top: 0;
  right: 0;
  width: 12.3rem;
  transition: all .5s linear; }
  .worker:hover {
    cursor: pointer; }
  .worker.active {
    transform: scale(1);
    top: 21%;
    right: 44%; }
    @media (min-width: 992px) and (max-width: 1279px) {
      .worker.active {
        right: 40%; } }
    @media (max-width: 991px) {
      .worker.active {
        top: 91%; } }
    @media (max-width: 600px) {
      .worker.active {
        transform: scale(0.7);
        top: 85%;
        right: 60%; } }

@media (min-width: 992px) {
  .worker-1 {
    top: 44%;
    right: 3%; }
  .worker-2 {
    top: 39%;
    right: 17.5%; }
  .worker-3 {
    top: 3%;
    right: 23%; }
  .worker-4 {
    top: 65%;
    right: 13%; }
  .worker-5 {
    top: 55%;
    right: 33%; }
  .worker-6 {
    top: 22.7%;
    right: 9.8%; } }

@media (max-width: 991px) {
  .worker-1 {
    top: 44%;
    right: 0%; }
  .worker-2 {
    top: 20%;
    right: 35%; }
  .worker-3 {
    top: 45%;
    right: 53%; }
  .worker-4 {
    top: 43%;
    right: 20%; }
  .worker-5 {
    top: 5%;
    right: 67%; }
  .worker-6 {
    top: 4%;
    right: 11%; } }

@media (max-width: 480px) {
  .worker.active {
    transform: scale(0.5);
    top: 85%;
    right: 50%; }
  .worker-1 {
    top: -15%;
    right: 23%; }
  .worker-2 {
    top: 20%;
    right: 23%; }
  .worker-3 {
    top: 45%;
    right: 53%; }
  .worker-4 {
    top: 43%;
    right: 0%; }
  .worker-5 {
    top: 5%;
    right: 55%; }
  .worker-6 {
    top: 4%;
    right: -9%; } }

@media (min-width: 1440px) {
  .quote-icon {
    margin-bottom: 3rem; } }

.quote-icon svg {
  width: 4.2rem;
  height: 3.3rem;
  fill: #eb7ce7; }

@media (min-width: 1280px) and (max-width: 1439px) {
  .quotes {
    padding-left: 2.5rem; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .quotes {
    padding-left: 4rem; } }

.quote {
  color: #fff;
  max-width: 19.5rem;
  display: none; }
  @media (max-width: 991px) {
    .quote {
      margin-left: auto;
      margin-right: auto; } }
  .quote.active {
    display: block; }
  .quote h4 {
    font-size: 2.4rem;
    color: #fff; }
    @media (min-width: 1440px) {
      .quote h4 {
        margin-bottom: 2rem; } }
  .quote .name {
    font-size: 1.2rem;
    font-weight: 700; }
    @media (min-width: 1440px) {
      .quote .name {
        margin-top: 1.5rem; } }
  @media (max-width: 480px) {
    .quote h4 {
      font-size: 1.5rem; }
    .quote p {
      font-size: .8rem; } }

.site-footer {
  background: url(../images/footer-bg.png) right center no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 0.9rem; }
  .site-footer .footer-logo {
    padding: 1.5rem 0 1rem; }
  .site-footer .copyright {
    padding: 1.25rem 0;
    background-color: rgba(150, 15, 255, 0.34);
    font-style: italic;
    color: #eb7ce7; }
  .site-footer hr {
    border-color: #fff;
    margin-bottom: 0; }
  .site-footer .row {
    align-items: center;
    justify-content: space-between; }
    .site-footer .row .col {
      padding-top: 1rem;
      padding-bottom: 1rem; }
  .site-footer .info ul {
    margin-bottom: 0; }
    .site-footer .info ul li {
      color: #fff;
      display: inline-block;
      padding-right: 1rem; }
      .site-footer .info ul li .sprite {
        margin-right: .5em; }
      .site-footer .info ul li svg {
        fill: currentColor; }
    @media (min-width: 768px) {
      .site-footer .info ul li + li {
        padding-left: 1rem;
        border-left: 1px solid #fff; } }
  .site-footer .social ul {
    margin-bottom: 0; }
    .site-footer .social ul li {
      display: inline-block; }
      .site-footer .social ul li a {
        display: inline-block;
        transition: all .3s linear;
        opacity: 1; }
        .site-footer .social ul li a svg {
          color: #fff;
          height: 2rem;
          width: 2rem; }
        .site-footer .social ul li a:hover {
          transform: scale(1.1);
          opacity: .8; }
    .site-footer .social ul li + li {
      padding-left: .5rem; }
  @media (max-width: 991px) {
    .site-footer {
      text-align: center; }
      .site-footer .row {
        justify-content: center; } }
  @media (max-width: 767px) {
    .site-footer .row .col {
      flex: 0 0 100%; }
    .site-footer .info ul li {
      display: block;
      margin-bottom: .5rem; } }

#three {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  opacity: .3;
  overflow: hidden;
  width: 45rem;
  height: 45rem; }
  @media (max-width: 1600px) {
    #three {
      width: 50vw;
      height: 50vw; } }
