@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700,900&display=swap');

@font-face {
    font-family: 'Lucida Grande';
    src: url('../fonts/LucidaGrande.woff2') format('woff2'),
        url('../fonts/LucidaGrande.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lucida Grande';
    src: url('../fonts/LucidaGrande-Bold.woff2') format('woff2'),
        url('../fonts/LucidaGrande-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

