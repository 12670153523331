html{
    font-size: 15px;
    @media(min-width: 768px){
	font-size: 16px;
    }
    @media(min-width: 1280px){
	font-size: 18px;
    }
    @media(min-width: 1440px){
	font-size: 19px;
    }
    @media(min-width: 1600px){
	font-size: 20px;
    }
}
h1{
    font-size: rem(52px);
}
h2{
    font-size: rem(48px);
}
@media(max-width:767px){
    h1{
	font-size: 2.3rem;
    }
    h2{
	font-size: 2rem;
    }
}
.btn {
    display: inline-block;
    font-weight: 700;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background: linear-gradient(to right, #aa35ca 0%,#794bdb 100%);
    border: 1px solid transparent;
    padding: rem(14px 65px);
    font-size: rem(24px);
    line-height: 1.5;
    border-radius: 2rem;
    transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(#aa35ca, 0.4);
}
.btn:hover {
    background: linear-gradient(to right, #794bdb 0%,#aa35ca 100%);
    box-shadow: 0 0 0 0.2rem rgba(#aa35ca, 0.4);
    color: #fff;
}

.site-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    .row{
	justify-content: space-between;
    }
    .logo{
	width: 14vw;
	@media(min-width:768px){
	    padding: 1rem 0;
	}
	img{
	    max-width: 100%;
	    height: auto;
	}
    }
    .nav-menu{
	@media(min-width:768px){
	    padding-top: 2rem;
	}
	li{
	    display: inline-block;
	    a{
		color: #fff;
		font-size: rem(23px);
		font-weight: 500;
		@media(min-width:768px){
		    padding-bottom: rem(13px);
		    border-bottom: rem(4px) solid transparent;
		}
		@media(min-width:768px) and (max-width:991px){
		    font-size: 1rem;
		}
	    }
	}
	.active{
	    a{
		font-weight: 900;
		border-color: #fff;
	    }
	}
	li+li{
	    @media(min-width:768px) and (max-width:991px){
		margin-left: 1rem;
	    }
	    @media(min-width:992px){
		margin-left: 2rem;
	    }
	}
    }
    #nav-icon{
	width: rem(50px);
	height: rem(45px);
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
    }

    #nav-icon span{
	display: block;
	position: absolute;
	height: rem(6px);
	width: 100%;
	background: rgba(#fff, .6);
	border-radius: rem(9px);
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
    }

    #nav-icon span:nth-child(1) {
	top: 0px;
    }

    #nav-icon span:nth-child(2) {
	top: rem(14px);
    }

    #nav-icon span:nth-child(3) {
	top: rem(28px);
    }
    #nav-icon.open span{
	background-color: #131313;
    }
    #nav-icon.open span:nth-child(1) {
	top: rem(18px);
	transform: rotate(135deg);
    }

    #nav-icon.open span:nth-child(2) {
	opacity: 0;
	left: rem(-60px);
    }

    #nav-icon.open span:nth-child(3) {
	top: rem(18px);
	transform: rotate(-135deg);
    }
    @media(max-width:767px){
	transition: all .5s linear;
	padding: .5rem 0;
	.logo{
	    width: 7rem;
	}
	.navigation{
	    display: none;
	    flex: 0 0 100%;
	}
	&.open-menu{
	    background-color: rgba(#fff, .9);
	}
	.row{
	    align-items: center;
	}
	.nav-menu {
	    margin: 0;
	    li {
		display: block;
		padding: .5rem 0;
		a{
		    color: #131313;
		    font-size: 1.2rem;
		}
	    }
	}
	.nav-toggler{
	    display: block!important;
	}
    }
}
.content-section{
    padding: 2rem 0;
    img{
	max-width: 100%;
	height: auto;
    }
}
.section-heading{
    text-align: center;
    position: relative;
    padding-top: 1.7rem;
    &:before{
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	width: rem(51px);
	height: rem(4px);
	background-color: #a933c8;
    }
}
.sub-heading{
    max-width: rem(510px);
    margin: 1rem auto;
    text-align: center;
}
/*who-we-are*/
.banner{
    display: flex;
    min-height: 51.3vw;
    align-items: center;
    .content-box{
	max-width: rem(600px);
	margin-top: 4rem;
	@media (max-width:991px){
	    max-width: 22rem;
	}
	h1{
	    span{
		color: #555555;
		font-weight: 400;
	    }
	}
	.btns{
	    margin-top: 2rem;
	    a+a{
		margin-left: 1.5rem;
	    }
	}
	svg{
	    width: rem(27px);
	    height: rem(27px);
	    fill: #b8b8b8;
	    transition: all .3s linear;
	    &:hover{
		fill: $pink;
	    }
	}
	.icon{
	    max-width: rem(81px);
	    margin-bottom: 1rem;
	}
	@media(min-width:1600px){
	    h1{
		font-size: rem(60px);
	    }
	    p{
		font-size: rem(30px);
	    }
	}
    }
}
.who-we-are{
    background: url(../images/who-we-are-bg.jpg) top right no-repeat;
    background-size: contain;
}
.home,
.how-we-do{
    background: url(../images/how-we-do-bg.jpg) top right no-repeat;
    background-size: contain;
}

.team{
    .team-item:nth-child(even){
	background-color: #f9faff;
    }
    .person{
	align-items: center;
	padding: rem(40px) 0;
	display: flex;

	@media(min-width:600px) and (max-width:991px){
	    .photo{
		flex: 0 0 35%;
	    }
	    .content{
		padding-left: 1rem;
	    }
	}
	@media(min-width:992px){
	    .photo{
		flex: 0 0 27%;
	    }
	    .content{
		padding-left: rem(50px);
	    }
	}
	@media(max-width:599px){
	    text-align: center;
	    flex-direction: column;
	    .photo{
		margin-bottom: 1rem;
	    }
	    .position{
		margin-left: auto;
		margin-right: auto;
	    }
	}
	h3{
	    color: #a933c8;
	    font-size: rem(21px);
	}
	.position{
	    font-style: italic;
	    font-size: 16px;
	    max-width: rem(415px);
	}
	.description{
	    border-top: 1px solid #d0d0d0;
	    padding-top: 1rem;
	    margin-top: 1rem;
	    p:last-child{
		margin-bottom: 0;
	    }
	}
    }
    .frame{
	padding: 8px;
	background-color: #fff;
	border: 1px solid #d0d0d0;
	border-radius: 19%;
	max-width: 316px;
	overflow: hidden;
	img{
	    border-radius: 18%;
	}
    }
}
.owl-carousel .owl-stage {
    display: flex;
    align-items: center;
}
.owl-theme .owl-dots .owl-dot span{
    border: 1px solid $pink;
}
.owl-carousel{
    padding: 1rem 0;
    .owl-item{
	display: flex;
	img{
	    margin: auto;
	}
    }
}
/**/
.infographic{
    margin: 2.5rem 0 3rem;
    .row{
	justify-content: space-evenly;
    }
    @media(min-width:992px){
	.col:nth-child(odd){
	    .stage{
		margin-top: 2rem;
	    }
	}
	.m-image{
	    display: none;
	}
    }
    @media(max-width:991px){
	display: flex;
	justify-content: center;
	.d-image{
	    display: none;
	}
	.row{
	    flex-direction: column;
	    padding-left: 1rem;
	}
	.stage{
	    h4{
		margin-bottom: 0;
		font-size: .9rem;
	    }
	    ul{
		margin-bottom: 0;
	    }
	}
    }
    @media(max-width:480px){
	.img-wrp{
	    flex: 0 0 55%;
	}
    }
}
.stage{
    h4{
	text-transform: uppercase;
	font-size: rem(21px);
	color: $body-color;
	margin-bottom: .6rem;
    }
    ul{
	font-size: rem(18px);
	li{
	    padding-left: 1rem;
	    background: url(../images/list.svg) left center no-repeat;
	    background-size: .6em;
	}
    }
    @media(min-width:992px) and (max-width:1279px){
	h4{
	    font-size: 1rem;
	}
	ul{
	    font-size: 0.75rem;
	}
    }
}
.col-content,
.col-tabs{
    flex: 0 0 50%;
    max-width: 50%;
    @media(max-width:767px){
	flex: 0 0 100%;
	max-width: 100%;
    }
}
@media(max-width:767px){
    .col-content{
	display: none;
    }
}
.tabs-wrp{
    .col{
	flex: 0 0 33.3%;
	max-width: 33.3%;
	margin-bottom: rem(30px);
    }
    @media(min-width:1600px){
	margin-right: -35px;
	margin-left: -35px;
	.col{
	    margin-bottom: rem(70px);
	    padding-right: 35px;
	    padding-left: 35px;
	}
    }
    @media(min-width:768px) and (max-width:1279px) {
	.col{
	    flex: 0 0 50%;
	    max-width: 50%;
	}
    }
    @media(max-width:480px) {
	margin-right: -5px;
	margin-left: -5px;
	.col{
	    margin-bottom: 10px;
	    padding-right: 5px;
	    padding-left: 5px;
	}
    }
}
.home-tabs{
    background: url(../images/home-tabs-bg.png) top left no-repeat;
    background-size: rem(359px 571px);
    .tab{
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	background-color: #fff;
	border: 2px solid #d0d0d0;
	border-radius: 20px;
	text-align: center;
	transition: all .3s linear;
	h4{
	    margin-top: 1rem;
	    margin-bottom: 0;
	    font-size: rem(22px);
	    color: #555;
	    line-height: 1.2;
	    transition: all .3s linear;
	    @media(max-width:480px) {
		font-size: .7rem;
	    }
	}
	img{
	    transition: all .1s linear;
	    @media(max-width:480px) {
		max-width: 50%;
	    }
	}
	&:hover,
	    &.active{
	    cursor: pointer;
	    background: linear-gradient(to bottom, #794bdb 0%,#aa35ca 100%);
	    border-color: #fff;
	    h4{
		color: #fff;
	    }
	    img{
		filter: brightness(0) invert(1);
	    }
	}
    }
    .tab-content{
	display: none;
	&.active{
	    display: block;
	}
	@media(min-width:768px) {
	    max-width: rem(410px);
	    margin-left: auto;
	}
	@media(max-width:768px) {
	    text-align: center;
	}
	p:not(:last-child){
	    margin-bottom: 2rem;
	}
	h2{
	    line-height: 1.2;
	    margin-bottom: 2rem;
	    position: relative;
	    padding-top: 1.5rem;
	    &:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: rem(51px);
		height: rem(4px);
		background-color: #a933c8;
		@media(max-width:768px) {
		    left: 50%;
		    transform: translateX(-50%);
		}
	    }
	}
    }
}
.our-projects{
    @media(min-width:992px){
	padding: 6rem 0 12rem;
    }
    background: url(../images/our-projects.jpg) top center no-repeat;
    background-size: cover;
    .inner{
	background-color: white;
	border: 1px solid white;
	border-radius: rem(50px);
	box-shadow: 0 0 24.8px 2.2px #f3f6ff;
	padding: 4rem 2rem;
    }
    .projects{
	position: relative;
	.owl-item{
	    justify-content: center;
	}
	.owl-dots{
	    margin-top: 2rem !important;
	}
	.owl-nav{
	    position: absolute;
	    left: -15%;
	    right: -15%;
	    top: 20%;
	    .owl-prev{
		position: absolute;
		left: 0;
	    }
	    .owl-next{
		position: absolute;
		right: 0;
	    }
	    span{
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(70px);
		height: rem(70px);
		background-color: white;
		border: 1px solid #d0d0d0;
		border-radius: 50%;
		svg{
		    fill: $pink;
		}
	    }
	}
    }
}
.meet{
    .col{
	flex: 0 0 100%;
	max-width: 100%;
    }
    @media(min-width:992px){
	margin-top: -5rem;
	background: url(../images/meet-bg.png) left top no-repeat;
	background-size: rem(973px 1152px);
	background-size: contain;
	.heading{
	    justify-content: flex-end;
	    .col{
		flex: 0 0 50%;
		max-width: 50%;
	    }
	}
	.section-heading{
	    text-align: left;
	    line-height: 1.2;
	    &:before{
		left: 0;
		transform: none;
	    }
	    span{
		color: #555555;
		font-weight: 400;
	    }
	}
	.meet-img{
	    display: none;
	}
    }
    @media(min-width:992px) and (max-width:1279px){
	background-position: left 3rem;
    }
    @media(max-width:991px){
	.heading{
	    padding-bottom: 300px;
	}
	background: url(../images/workers-bg-sm.png) 50% 17% no-repeat;
	background-size: contain;
    }
}
.meet-wrp{
    position: relative;
    min-height: 25rem;
    @media(min-width:992px){
	background: url(../images/workers-bg.png) right center no-repeat;
	background-size: contain;
    }
    @media(min-width:1280px) and (max-width:1439px){
	min-height: 36rem;
    }
    @media(min-width:1440px){
	min-height: 48rem;
    }
    @media(max-width:991px){
	.container{
	    position: absolute;
	    top: 44%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    text-align: center;
	}
    }
}
.workers{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    @media(max-width:991px){
	height: 300px;
	top: -300px;
	bottom: auto;
    }
}
.worker{
    background-color: #e4e7ef;
    border: 8px solid white;
    box-shadow: 0 0 24.8px 2.2px #e5eaf9;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(.5);
    position: absolute;
    top: 0;
    right: 0;
    width: rem(246px);
    transition: all .5s linear;
    &:hover{
	cursor: pointer;
    }
    &.active{
	transform: scale(1);
	top: 21%;
	right: 44%;
	@media(min-width:992px) and (max-width:1279px){
	    right: 40%;
	}
	@media(max-width:991px){
	    top: 91%;
	}
	@media(max-width:600px){
	    transform: scale(.7);
	    top: 85%;
	    right: 60%;
	}
    }
}
@media(min-width:992px){
    .worker-1{
	top: 44%;
	right: 3%;
    }
    .worker-2{
	top: 39%;
	right: 17.5%;
    }
    .worker-3{
	top: 3%;
	right: 23%;
    }
    .worker-4{
	top: 65%;
	right: 13%;
    }
    .worker-5{
	top: 55%;
	right: 33%;
    }
    .worker-6{
	top: 22.7%;
	right: 9.8%;
    }
}
@media(max-width:991px){
    .worker-1{
	top: 44%;
	right: 0%;
    }
    .worker-2{
	top: 20%;
	right: 35%;
    }
    .worker-3{
	top: 45%;
	right: 53%;
    }
    .worker-4{
	top: 43%;
	right: 20%;
    }
    .worker-5{
	top: 5%;
	right: 67%;
    }
    .worker-6{
	top: 4%;
	right: 11%;
    }
}
@media(max-width:480px){
    .worker.active {
	transform: scale(0.5);
	top: 85%;
	right: 50%;
    }
    .worker-1{
	top: -15%;
	right: 23%;
    }
    .worker-2{
	top: 20%;
	right: 23%;
    }
    .worker-3{
	top: 45%;
	right: 53%;
    }
    .worker-4{
	top: 43%;
	right: 0%;
    }
    .worker-5{
	top: 5%;
	right: 55%;
    }
    .worker-6{
	top: 4%;
	right: -9%;
    }
}
.quote-icon{
    @media(min-width:1440px){
	margin-bottom: 3rem;
    }
    svg{
	width: rem(84px);
	height: rem(66px);
	fill: #eb7ce7;
    }
}
.quotes{
    @media(min-width:1280px) and (max-width:1439px){
	padding-left: 2.5rem;
    }
    @media(min-width:992px) and (max-width:1279px){
	padding-left: 4rem;
    }
}
.quote{
    color: #fff;
    max-width: rem(390px);
    display: none;
    @media(max-width:991px){
	margin-left: auto;
	margin-right: auto;
    }
    &.active{
	display: block;
    }
    h4{
	font-size: rem(48px);
	color: #fff;
	@media(min-width:1440px){
	    margin-bottom: 2rem;
	}
    }
    .name{
	@media(min-width:1440px){
	    margin-top: 1.5rem;
	}
	font-size: rem(24px);
	font-weight: 700;
    }
    @media(max-width:480px){
	h4 {
	    font-size: 1.5rem;
	}
	p{
	    font-size: .8rem;
	}
    }
}

//Site footer
.site-footer{
    background: url(../images/footer-bg.png) right center no-repeat;
    background-size: cover;
    color: #fff;
    font-size: rem(18px);
    .footer-logo{
	padding: 1.5rem 0 1rem;
    }
    .copyright{
	padding: rem(25px 0);
	background-color: rgba(#960fff, 0.34);
	font-style: italic;
	color: #eb7ce7;
    }
    hr{
	border-color: #fff;
	margin-bottom: 0;
    }
    .row{
	align-items: center;
	justify-content: space-between;
	.col{
	    padding-top: 1rem;
	    padding-bottom: 1rem;
	}
    }
    .info ul{
	margin-bottom: 0;
	li{
	    color: #fff;
	    display: inline-block;
	    padding-right: 1rem;
	    .sprite{
		margin-right: .5em;
	    }
	    svg{
		fill:currentColor;
	    }
	}
	@media(min-width:768px){
	    li+li{
		padding-left: 1rem;
		border-left: 1px solid #fff;
	    }
	}
    }
    .social ul{
	margin-bottom: 0;
	li{
	    display: inline-block;
	    a{
		display: inline-block;
		transition: all .3s linear;
		opacity: 1;
		svg{
		    color: #fff;
		    height: 2rem;
		    width: 2rem;
		}
		&:hover{
		    transform: scale(1.1);
		    opacity: .8;
		}
	    }
	}
	li+li{
	    padding-left: .5rem;
	}
    }
    @media(max-width:991px){
	text-align: center;
	.row{
	    justify-content: center;
	}
    }
    @media(max-width:767px){
	.row{
	    .col{
		flex: 0 0 100%;
	    }
	}
	.info ul li {
	    display: block;
	    margin-bottom: .5rem;
	}
    }
}

#three{
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    opacity: .3;
    overflow: hidden;
    width: rem(900px);
    height: rem(900px);
    @media(max-width:1600px) {
	width: 50vw;
	height: 50vw;
    }
}
