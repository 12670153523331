$font-family-base: 'Roboto', sans-serif;
$font-size-base: 1rem;
$font-weight-base: 400;
$line-height-base: 1.5;
$font-weight-bolder: 700;
$body-color: #6f6f6f;
$body-bg: #fff;

$pink: #a933c8;

$headings-margin-bottom: 1rem;
$paragraph-margin-bottom: .5rem;

$link-color: $body-color;
$link-hover-color: $link-color;
$link-hover-decoration: none;


$label-margin-bottom: .5rem;

// OWL
$dot-width: rem(16px);
$dot-height: rem(16px);

$dot-background: #fff;
$dot-background-active: $pink;

$nav-background: transparent;
$nav-background-hover: transparent;